import { Cookies } from 'react-cookie';

import { BE_API_KEY } from '../constants';

const cookies = new Cookies();

export const getAuthorizedHeader = () => {
  const token = cookies.get('token');
  const headers = {
    'Content-Type': 'application/json',
  };
  if (token) {
    headers['Authorization'] = `Bearer ${token}`;
  } else {
    // only add BE API key if it does not have token to prevent leaking the key as much as possible
    headers['be_api_key'] = BE_API_KEY;
  }
  return headers;
};

export const fetchWithTimeout = async (resource, options = {}) => {
  const { timeout = 8000 } = options;

  const controller = new window.AbortController();
  const id = setTimeout(() => controller.abort(), timeout);
  const response = await fetch(resource, {
    ...options,
    signal: controller.signal
  });
  clearTimeout(id);
  return response;
};

export const fetchWithErrorHandling = async (...args) => {
  return fetch(...args)
    .then(async (response) => {
      if (!response.ok) {
        const json = await response.json();
        throw Error(json.message || response.statusText);
      }
      return (await response.json()).data;
    })
    .catch((err) => {
      if (err.name === 'AbortError') {
        // abort error is expected when the request is aborted
        return;
      }

      throw err;
    });
};