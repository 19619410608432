import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';

import { makeStyles } from 'tss-react/mui';
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  TextField,
  Typography,
} from '@mui/material';

import { getAllTechnologyLevels } from '../../apis/technologyLevelsApi';

import { generateTechnologyString } from '../../utils/technologyLevelsUtils';
import { isEmptyValue } from '../../utils/commonUtils';

import { CUSTOM_TECHNOLOGY_LEVELS } from '../../constants/technologyLevelsConstants';
import { colors } from '../../palette';

const useStyles = makeStyles()((theme) => ({
  inputField: {
    width: '100%',
    marginBottom: '13px',
    textTransform: 'none',
    color: colors.fontGrey,
  },
  inputLabel: {
    fontSize: 16,
    width: '100%',
    fontWeight: 600,
    color: colors.fontBlack,
  },
  inputLabelInfo: {
    fontWeight: 500,
    color: colors.fontBlack,
    width: '100%',
    paddingTop: '0.2rem',
    lineHeight: '2',
  },
}));

/**
 * A form component to select manufacturing capabilities.
 *
 * @prop {function} handleMachiningCapabilities - a callback function to handle selected manufacturing capabilities.
 * @prop {function} handleManufacturingData - a callback function to get manufacturing data.
 * @prop {bool} showHeaderLevel2 - a boolean to show or hide level 2 header.
 * @prop {string} textHeaderLevel2 - a string to show as level 2 header.
 */
function ManufacturingCapabilitiesForm(props) {
  const {
    handleMachiningCapabilities = () => {},
    handleManufacturingData = () => {},
    showHeaderLevel2 = true,
    textHeaderLevel2 = 'Select at least one',
  } = props;
  const { classes } = useStyles();

  const [technologyLevelsState, setTechnologyLevelsState] = useState([]);

  useQuery(['getAllTechnologyLevels'], () =>
    getAllTechnologyLevels({ isCustom: 0 }).then(setTechnologyLevelsState)
  );

  useEffect(() => {
    if (!isEmptyValue(technologyLevelsState)) {
      const technologyString = generateTechnologyString(technologyLevelsState);
      handleMachiningCapabilities(technologyString);
      handleManufacturingData(technologyLevelsState);
    }
  }, [
    technologyLevelsState,
    handleMachiningCapabilities,
    handleManufacturingData,
  ]);

  const handleSelectedManufacturing = (e) => {
    const { checked, value } = e.target;
    setTechnologyLevelsState((prev) =>
      prev.map((item) => {
        if (item.id === Number(value)) {
          item.selected = checked;
        }
        return item;
      })
    );
  };

  const handleCustomValue = (tech, e) => {
    const value = e.target.value;
    setTechnologyLevelsState((prev) =>
      prev.map((item) => {
        if (item.id === tech.id) {
          item.customValue = value;
        }
        return item;
      })
    );
  };

  const stylingLevel = (tech) => {
    let style = { paddingLeft: '0px' };
    if (tech.level === 2) {
      style.paddingLeft = '2rem';
      style.display = 'none';
      if (
        technologyLevelsState.some(
          (item) =>
            item.selected && item.level === 1 && item.technology === tech.parent
        )
      ) {
        style.display = 'inline-flex';
      }
    } else if (tech.level === 3) {
      style.paddingLeft = '4rem';
      style.display = 'none';
      if (
        technologyLevelsState.some(
          (item) =>
            item.selected && item.level === 2 && item.technology === tech.parent
        )
      ) {
        style.display = 'inline-flex';
      }
    }
    return style;
  };

  const renderLevel2Header = (tech) => {
    if (
      !CUSTOM_TECHNOLOGY_LEVELS.includes(tech.technology) &&
      technologyLevelsState.some(
        (item) => item.selected && item.id === tech.id && item.level === 1
      ) &&
      technologyLevelsState.some((item) => tech.technology === item.parent) &&
      showHeaderLevel2
    ) {
      return (
        <Typography
          style={{ ...stylingLevel(tech), paddingLeft: '2rem', paddingTop: 0 }}
          className={classes.inputLabelInfo}
        >
          {textHeaderLevel2}
        </Typography>
      );
    }
  };

  const renderCustomField = (tech) => {
    if (
      CUSTOM_TECHNOLOGY_LEVELS.includes(tech.technology) &&
      technologyLevelsState.some((item) => item.selected && item.id === tech.id)
    ) {
      return (
        <div style={{ ...stylingLevel(tech), width: '100%' }}>
          <TextField
            id='mc-other'
            name='mc-other'
            label='Custom'
            variant='outlined'
            autoFocus
            className={classes.inputField}
            size='small'
            onChange={(e) => handleCustomValue(tech, e)}
            value={tech.customValue}
          />
        </div>
      );
    }
  };

  return (
    <React.Fragment>
      <Typography className={classes.inputLabel}>
        Manufacturing Capabilities
      </Typography>
      <Typography className={classes.inputLabelInfo}>
        Select all that apply
      </Typography>
      <FormGroup>
        {technologyLevelsState?.map((tech) => {
          return (
            <div key={tech.id}>
              <FormControlLabel
                control={<Checkbox onChange={handleSelectedManufacturing} />}
                label={tech.technology}
                value={tech.id}
                style={stylingLevel(tech)}
              />
              {renderLevel2Header(tech)}
              {renderCustomField(tech)}
            </div>
          );
        })}
      </FormGroup>
    </React.Fragment>
  );
}

export default ManufacturingCapabilitiesForm;
