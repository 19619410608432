import { CUSTOM_TECHNOLOGY_LEVELS } from '../constants/technologyLevelsConstants';
import { isEmptyValue } from './commonUtils';

/**
 * Builds a hierarchy of technologies from the given data.
 *
 * @param {Array} data - Array of objects with technology, level, and parent properties.
 * @return {Array} - The root level of the hierarchy.
 */
export const buildHierarchy = (data) => {
  const map = {};
  const result = [];

  // Create a map with technology as the key and each item as a value
  data.forEach((item) => {
    map[item.technology] = { ...item, children: [] }; // Ensure each item has a 'children' array
  });

  // Now, assign each item to its parent
  data.forEach((item) => {
    if (item.parent) {
      const parent = map[item.parent];
      if (parent) {
        parent.children.push(map[item.technology]);
      }
    } else {
      result.push(map[item.technology]);
    }
  });

  return result;
};

export const generateTechnologyString = (data) => {
  if (isEmptyValue(data)) {
    return '';
  }
  // Filter the data to include only selected items
  const selectedItems = data
    .filter((item) => item.selected)
    .sort((a, b) => a.level - b.level);

  const result = [];
  const map = {};

  const getValue = (item) => {
    return item.customValue
      ? `${item.technology} - ${item.customValue}`
      : item.technology;
  };

  selectedItems.forEach((item) => {
    if (item.level === 1) {
      map[item.technologyKey] = {
        level1: item.technology,
        technologies: [getValue(item)],
      };
    } else if (item.level === 2) {
      // For level 2, ensure it has level 1 as parent, then append the technology
      if (map[item.technologyKey]?.level1 === item.parent) {
        map[item.technologyKey] = {
          ...map[item.technologyKey],
          level2: item.technology,
          technologies: [...map[item.technologyKey].technologies, getValue(item)],
        };
      }
    } else if (item.level === 3) {
      // For level 3, ensure it has level 2 as parent, then append the technology
      if (map[item.technologyKey]?.level2 === item.parent) {
        map[item.technologyKey] = {
          ...map[item.technologyKey],
          level3: item.technology,
          technologies: [...map[item.technologyKey].technologies, getValue(item)],
        };
      }
    }
  });

  Object.values(map).forEach((item) => {
    const { technologies } = item;
    if (!isEmptyValue(item.technologies)) {
      const technologiesString = technologies.join(', ');
      result.push(technologiesString);
    }
  });

  return result.join(', ');
};

export const isManufacturingCapabilitiesValidated = (
  manufacturingCapabilitiesData,
  options = {}
) => {
  const { level2RequiredIfAny = true } = options;
  if (isEmptyValue(manufacturingCapabilitiesData)) {
    return false;
  }
  // should have at least one selected
  if (!manufacturingCapabilitiesData.some((item) => item.selected)) {
    return false;
  }
  for (const item of manufacturingCapabilitiesData) {
    if (item.selected) {
      if (item.level === 1) {
        const itemsLevel2 = manufacturingCapabilitiesData.filter(
          (mc) => mc.parent === item.technology && mc.level === 2
        );
        // level 2 should have at least one selected if any
        if (
          !isEmptyValue(itemsLevel2) &&
          !itemsLevel2.some((mc) => mc.selected) &&
          level2RequiredIfAny
        ) {
          return false;
        }
      }
      // custom value should not be empty
      if (
        CUSTOM_TECHNOLOGY_LEVELS.includes(item.technology) &&
        isEmptyValue(item.customValue)
      ) {
        return false;
      }
    }
  }
  return true;
};
